import React, { useState, forwardRef, useEffect } from "react";
import Proptypes from "prop-types";
import {
	Button,
	Link,
	Text,
	VerticalList,
	Icon,
	Accordion,
	Separator,
	CallUsModal,
	AuthenticationModal,
	ModalForm,
} from "@website-builder/ui/shared/elements";
import FocusLock from "react-focus-lock";
import CourseMenu from "../NavigationHeader/CourseMenu";
import Dropdown from "../NavigationHeader/Dropdown";
import {
	AncillaryNav,
	AncillaryNavActions,
	Caret,
	DropdownLabelWrapper,
	NavActions,
	NavBar,
	NavBrand,
	NavHeaderWrapper,
	NavMenu,
	NavMenuMobile,
	NavMenuMobileFooter,
	NavWrapper,
	StickyHeaderWrapper,
	PencilBannerWrapper,
	UserAvatarWrapper,
	MenuFooter,
	TopNav,
	TopNavItem,
	TopNavMobile,
	TopNavMobileItem,
} from "./style";
import { useMediaQuery } from "@react-hook/media-query";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import {
	addToDataLayer,
	getXCSRFToken,
} from "@website-builder/utilities/utils/utils.js";
import { getUniqueId } from "@website-builder/utilities/utils/helpers.js";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import MegaCourseMenu from "../NavigationHeader/MegaCourseMenu";

const DropdownLabel = forwardRef(
	({ label, color, lightHeader, ...restProps }, ref) => (
		<DropdownLabelWrapper
			ref={ref}
			lightHeader={lightHeader}
			aria-haspopup="dialog"
			{...restProps}
		>
			<Text variant="paragraph_S_bold" color={color}>
				{label}
			</Text>
			<Caret color={color} aria-hidden="true" {...restProps} />
		</DropdownLabelWrapper>
	),
);
const initialDropdownState = {
	dropdown1: false,
	dropdown2: false,
	dropdown3: false,
	dropdown4: false,
};

const initialHeaderStyle = {
	backgroundColor: "transparent",
	visibility: "visible",
	opacity: 1,
};

const isB2U = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
const NavigationHeaderGeneric = (props) => {
	const {
		logo,
		logoOnDark,
		menuList,
		callUsLabel,
		callUsModalDetails,
		loginLabel,
		paidLander,
		apiBaseUrl,
		globalHostName,
		isStaticBuild,
		isLogoCenteredInMobile,
		setUserDetailsProp,
		disableLogin,
		disableHamburger,
		topNav,
		hidePreNav,
		pageSection,
		isHeaderSticky,
		isHeaderLight,
		headerCTA: cta,
		pencilBanner,
		context,
		basicConfig,
		stickyHeader = {},
	} = props;

	const {
		applyNowLink = {},
		modalConfiguration = [],
		headerLinks = [],
		backgroundColor: stickyHeaderBackgroundColor = {},
		buttonColor: stickyHeaderButtonColor = {},
		buttonTextColor: stickyHeaderButtonTextColor = {},
		linkColor: stickyHeaderLinkColor = {},
	} = stickyHeader || {};

	const handleDropdownClick = (label) => {
		// data event start
		addToDataLayer({
			event: "header_nav_menu_item_click",
			click_text: label,
		});
		// data event end
	};
	const isStickyheaderAdded = Object.keys(stickyHeader)?.length > 0;

	const [isOpen, setIsOpen] = useState(initialDropdownState);
	const [headerStyle, setHeaderStyle] = useState(initialHeaderStyle);
	const [lightHeader, setLightHeader] = useState(isHeaderLight);
	const [mobileMenuIcon, setMobileMenuIcon] = useState("hamburger");
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [userAvatar, setUserAvatar] = useState(null);
	const [showUserMenu, setShowUserMenu] = useState(false);
	const [showMentorDashboard, setShowMentorDashboard] = useState(false);
	const [showCareerDashboard, setShowCareerDashboard] = useState(false);
	const [showMyCourses, setShowMyCourses] = useState(false);
	const [showMyLearning, setShowMyLearning] = useState(false);
	const [isStudent, setIsStudent] = useState(false);
	const [showCallUsModal, setShowCallUsModal] = useState(false);
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const [isMobile, setIsMobile] = useState(true);
	const [showCTAModal, setShowCTAModal] = useState(false);
	const [modalContent, setmodalContent] = useState({});
	const [showPencilBanner, setShowPencilBanner] = useState(
		pencilBanner?.text ? true : false,
	);

	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const headerBackground = !lightHeader
		? "var(--primary-neutral-white)"
		: "var(--primary-forest-600)";
	const userApiEndpoint = `${apiBaseUrl}/user/`;
	const signOutApiEndPoint = `${apiBaseUrl}/accounts/logout/`;

	const handleCallUsModalLink = (event) => {
		event.preventDefault();
		setShowCallUsModal(true);
	};
	const filterApplyNowButton = (cta) => {
		return cta?.props?.buttonText?.trim?.()?.toUpperCase?.() !== "APPLY NOW";
	};

	useEffect(() => {
		if (!disableLogin) {
			fetch(userApiEndpoint, {
				credentials: "include",
			})
				.then((res) => res.json())
				.then((response) => {
					const { code } = response;
					setUserDetailsProp(response);
					if (code === 200) {
						const {
							firstname,
							picture,
							is_admin,
							is_mentor,
							technical_coach_id,
							behavioural_coach_id,
							redirect_link,
							lpc = true,
						} = response;

						if (!lpc) {
							window.location.href = "/password-reset/";
						} else if (
							(window.location.pathname === "/" ||
								window.location.pathname === "/home") &&
							redirect_link !== "/"
						) {
							window.location.href = globalHostName + redirect_link;
						}
						const isStudentCheck = !(
							is_mentor ||
							is_admin ||
							behavioural_coach_id ||
							technical_coach_id
						);
						if (isStudentCheck) {
							setIsStudent(true);
						}
						if (is_admin || is_mentor) {
							setShowMentorDashboard(true);
						}
						if (technical_coach_id || behavioural_coach_id) {
							setShowCareerDashboard(true);
						}
						if (is_mentor) {
							setShowMyCourses(true);
						} else {
							setShowMyLearning(true);
						}
						setUserAvatar({
							firstname,
							picture,
						});
					} else if (
						isStaticBuild &&
						code === 500 &&
						window.location.pathname.includes("/refer")
					) {
						window.location.href = globalHostName + "/login/";
					}
				})
				.catch((err) => {
					setUserAvatar(null);
				});
		}

		if (isB2U) {
			setHeaderStyle({ ...headerStyle });
		} else {
			const scrollHandler = () => {
				if (window.scrollY <= 32) {
					if (!showMobileMenu) {
						//header at top, transparent background
						setHeaderStyle({ ...headerStyle, backgroundColor: "transparent" });
						setLightHeader(isHeaderLight);
					}
				} else {
					//else, white background
					setHeaderStyle({
						...headerStyle,
						backgroundColor: "var(--primary-neutral-white)",
					});
					setLightHeader(false);
				}
			};
			if (isHeaderSticky) {
				window.addEventListener("scroll", scrollHandler);
				return () => {
					window.removeEventListener("scroll", scrollHandler);
				};
			}
		}
	}, [showMobileMenu]);

	const signOut = async () => {
		// Using try catch as the api response is a redirect to the root domain
		try {
			await fetch(signOutApiEndPoint, {
				credentials: "include",
				redirect: "manual",
			});
		} catch (err) {
			console.error("SIGNOT", err);
		}

		setUserAvatar(null);
	};

	const closeDropDown = React.useCallback(
		(menuIndex) => {
			if (isOpen[`dropdown${menuIndex}`])
				setIsOpen({ ...isOpen, [`dropdown${menuIndex}`]: false });
		},
		[isOpen],
	);

	const openDropDown = (menuIndex) => {
		const val = !isOpen[`dropdown${menuIndex}`];
		setIsOpen({ ...initialDropdownState, [`dropdown${menuIndex}`]: val });
	};

	useEffect(() => {
		const handleEsc = (e) => {
			if (e.key === "Escape") {
				setIsOpen(initialDropdownState);
			}
		};
		window.addEventListener("keydown", handleEsc);
		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, []);

	const linkListToLinkJSX = (item, menuIndex) => (
		<Link
			className="bold-link"
			href={item?.cta?.link?.link}
			onClick={() => {
				closeDropDown(menuIndex);
				handleCTAClick(item.cta, {
					click_text: item.text,
					redirect_to: item.cta.link.link,
					page_section: pageSection,
				});
			}}
		>
			<Text
				variant="paragraph_M"
				color={{ base: "primary", color: "neutral", shade: "nightshade-800" }}
			>
				{item.text}
			</Text>
		</Link>
	);

	const dropdownFooterJSX = (footer) => (
		<MenuFooter>
			<Separator />
			<Text variant="paragraph_S">{footer.text}</Text>
			<Link
				href={footer?.linkCta?.link?.link}
				target={footer?.linkCta?.link?.target}
				arrowCta={footer?.linkCta?.type === "arrowCta"}
				onClick={(e) => {
					// This is where we have the career quiz typeform
					// We don't want to enable cmd/ctrl+click here, so e is not passed to handleCTAClick
					e.preventDefault();
					handleCTAClick(
						footer.linkCta,
						{
							page_section: pageSection,
							click_text: footer.linkCta?.linkText,
							redirect_to: footer.linkCta?.link?.link,
						},
						{ context },
					);
				}}
			>
				<Text variant="paragraph_M_bold">{footer.linkCta?.linkText}</Text>
			</Link>
			<Button
				buttonConfiguration={isB2U && footer.buttonCta}
				buttonText={footer.buttonCta?.buttonText}
				onClick={() => {
					handleCTAClick(footer.buttonCta, {
						page_section: pageSection,
						click_text: footer.buttonCta?.buttonText,
						redirect_to: footer.buttonCta?.link?.link,
					});
				}}
			/>
		</MenuFooter>
	);
	const textColor = !lightHeader
		? { base: "primary", color: "neutral", shade: "nightshade-800" }
		: { base: "primary", color: "neutral", shade: "white" };
	const accordionMenu = [];
	menuList.forEach((menu, index) => {
		const accordianItem = {
			heading: menu?.label,
			content: {},
			isHeadingALink: false,
		};
		switch (menu?.type) {
			case "CourseMenu":
				accordianItem.content = (
					<CourseMenu
						{...menu?.items}
						pageSection={pageSection}
						isMobile={true}
						context={context}
						onClose={() => {
							setShowMobileMenu(false);
							setMobileMenuIcon("hamburger");
						}}
					/>
				);
				break;
			case "megaCourseMenu":
				accordianItem.content = (
					<MegaCourseMenu
						{...menu?.items}
						pageSection={pageSection}
						isMobile={true}
						context={context}
					/>
				);
				break;
			case "GenericMenu":
				let listItems = menu?.items?.map?.((item) =>
					linkListToLinkJSX(item, index + 1),
				);
				if (menu?.footer) {
					listItems?.push(dropdownFooterJSX(menu?.footer));
				}
				accordianItem.content = <VerticalList listItems={listItems} />;
				break;

			case "LinkMenu":
				accordianItem.heading = (
					<Link
						href={menu.cta?.link?.link}
						target={menu.cta?.link?.target}
						key={index}
						onClick={() => {
							handleCTAClick(menu.cta, {
								click_text: menu.text,
								redirect_to: menu.cta.link.link,
								page_section: pageSection,
							});
						}}
					>
						<Text variant="headline_6">{menu.text}</Text>
					</Link>
				);
				accordianItem.isHeadingALink = true;
				break;
		}
		accordionMenu.push(accordianItem);
	});

	const handleHamburgerClick = () => {
		if (mobileMenuIcon === "hamburger") {
			setHeaderStyle({ ...headerStyle, backgroundColor: headerBackground });
			setMobileMenuIcon("xMark32");
		} else {
			setHeaderStyle({
				...headerStyle,
				backgroundColor:
					window.scrollY <= 32 ? "transparent" : "var(--primary-neutral-white)",
			});
			setMobileMenuIcon("hamburger");
		}

		const val = !showMobileMenu;
		setShowMobileMenu(val);
	};

	const getLogo = () => {
		if (logo?.hasOwnProperty("type") && basicConfig) {
			if (logo.type === "primary") {
				return basicConfig?.primaryLogo;
			} else if (logo.type === "secondary") {
				return basicConfig?.secondaryLogo;
			} else if (logo.type === "custom") {
				return logo?.image;
			}
		}
		return null;
	};
	let brandLogoInput = logo?.image;
	if (lightHeader && logoOnDark?.image) {
		brandLogoInput = logoOnDark?.image;
	}
	const brandLogoAlt = !lightHeader ? logo?.altTag : logoOnDark?.altTag;
	const brandLogoDefault = !lightHeader
		? "https://res.cloudinary.com/springboard-images/image/upload/v1677268226/website-redesign/brand-logos/springboard-logo-2023.svg"
		: "https://res.cloudinary.com/springboard-images/image/upload/v1677268226/website-redesign/brand-logos/springboard-logo-rev-2023.svg";

	const buttonJSX =
		cta && cta.length > 0
			? cta.map((ctaEle, index) => (
					<Button
						buttonConfiguration={isB2U && ctaEle}
						darkBg={!isMobile && !isB2U ? lightHeader : false}
						buttonText={ctaEle?.buttonText || "Apply Now"}
						key={`button${index + 1}`}
						type={ctaEle?.type || "primary"}
						onClick={() => {
							if (showMobileMenu) {
								setShowMobileMenu(false);
								setMobileMenuIcon("hamburger");
							}
							if (
								ctaEle?.opensModal &&
								ctaEle?.modalContent &&
								ctaEle?.modalContent[0]
							) {
								// ctaEle['link']['link'] = "#";
								setShowCTAModal(true);
								setmodalContent(ctaEle?.modalContent[0]);
							} else {
								console.error(
									"This button opens a modal but the modal content is undefined.",
								);
							}
							handleCTAClick(ctaEle, {
								page_section: pageSection,
								click_text: ctaEle?.buttonText,
								redirect_to: ctaEle?.link?.link,
							});
						}}
					/>
				))
			: [];

	const callUsJSX = callUsLabel ? (
		<>
			<Link
				darkBg={!isMobile ? lightHeader : false}
				iconConfig={{ iconName: "call" }}
				onClick={handleCallUsModalLink}
				className="call-us-link"
				as="button"
				aria-expanded={showCallUsModal}
				aria-haspopup="dialog"
				aria-controls={
					showCallUsModal ? "ancillary-nav-header-call-us-modal" : null
				}
			>
				<Text
					variant="paragraph_S_bold"
					color={
						isMobile
							? { base: "primary", color: "neutral", shade: "nightshade-800" }
							: textColor
					}
				>
					Call us {callUsLabel}
				</Text>
			</Link>
		</>
	) : null;

	const userAvatarJSX = !disableLogin ? (
		<UserAvatarWrapper
			onClick={() => setShowUserMenu(!showUserMenu)}
			showUserMenu={showUserMenu}
		>
			<div className="user-info-wrapper">
				<div className="imageWrapper">
					<img src={userAvatar?.picture} />
				</div>
				<div className="userName">
					<Text variant="paragraph_S_bold">{userAvatar?.firstname}</Text>
				</div>
			</div>
			<div className="userMenuWrapper">
				<div className="backdrop" onClick={() => setShowUserMenu(false)}></div>
				<div className="userMenu">
					{showMentorDashboard && (
						<a href="/mentor-dashboard">Mentor Dashboard</a>
					)}
					{showCareerDashboard && (
						<a href="/career-dashboard">Coach Dashboard</a>
					)}
					{showMyLearning && <a href="/profile/#/my-learning">My Learning</a>}
					{showMyCourses && <a href="/profile/#/my-courses">My Courses</a>}
					<Separator darkBg />
					<a href="/profile">Profile</a>
					<a href="/profile/#/billing">My Account</a>
					<a href="/profile/#/settings">Settings</a>
					<Separator darkBg />
					<span onClick={signOut}>Sign Out</span>
				</div>
			</div>
		</UserAvatarWrapper>
	) : (
		<></>
	);

	const topNavItemsForMobile = topNav?.length ? (
		<TopNavMobile bgColor={topNav?.[0]?.bgColor}>
			{topNav?.[0]?.topNavItems.map((topNavItem, index) => {
				return (
					<TopNavMobileItem key={index} {...topNav?.[0]}>
						<Link
							className="top-nav-mobile-link"
							href={topNavItem?.link?.link}
							arrowCta
						>
							{topNavItem.text}
						</Link>
					</TopNavMobileItem>
				);
			})}
		</TopNavMobile>
	) : null;

	const handleCallLinkClick = () => {
		addToDataLayer({
			event: "call_start",
			page_section: pageSection,
			phone_no: phoneNumber,
		});
	};

	return (
		<NavHeaderWrapper
			isPencilBannerClosed={!showPencilBanner}
			className="navigation-header"
		>
			<FocusLock disabled={!showMobileMenu}>
				{showPencilBanner ? (
					<PencilBannerWrapper
						stickyHeader={isHeaderSticky}
						bgColor={pencilBanner?.backgroundColor?.color}
						textColor={pencilBanner?.textColor?.color}
						hasIcons={pencilBanner?.hasIcons}
					>
						<div className="pencil-banner-text">
							{pencilBanner?.hasIcons && (
								<Icon
									iconName="checkCircle"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									style={{ strokeWidth: 1.5 }}
									alt="checkIcon"
								/>
							)}
							<Text variant="paragraph_M_bold">{pencilBanner?.text}</Text>
							{pencilBanner?.hasIcons && (
								<Icon
									iconName="checkCircle"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									style={{ strokeWidth: 1.5 }}
									alt="checkIcon"
								/>
							)}
						</div>
						{pencilBanner?.hasCloseButton ? (
							<div
								className="closeButtonWrapper"
								onClick={() => {
									setShowPencilBanner(false);
								}}
							>
								<Icon
									iconName="xMark24"
									stroke={pencilBanner?.textColor?.color || "#C3F570"}
									fill={pencilBanner?.textColor?.color || "#C3F570"}
									alt="close modal"
								/>
							</div>
						) : null}
					</PencilBannerWrapper>
				) : null}
				<NavWrapper
					style={headerStyle}
					isLogoCenteredInMobile={isLogoCenteredInMobile}
					className={`navigation-header-generic ${isB2U ? "up-header" : ""}`}
					lightHeader={lightHeader}
					stickyHeader={isHeaderSticky}
					showMobileMenu={showMobileMenu}
					isPencilBannerClosed={!showPencilBanner}
				>
					{headerLinks.length ? (
						<StickyHeaderWrapper
							backgroundColor={stickyHeaderBackgroundColor?.color}
							linkColor={stickyHeaderLinkColor?.color}
							buttonColor={stickyHeaderButtonColor?.color}
							buttonTextColor={stickyHeaderButtonTextColor?.color}
						>
							<nav>
								<ul className="navbar">
									{headerLinks.map(
										({
											link: { type, link, target } = {},
											label,
											component,
											phoneNumber,
											_uid: id = getUniqueId(),
										}) => (
											<li className="nav-item" key={id}>
												{component === "headerLink" ? (
													<Link
														className="nav-link"
														href={`#${link}`}
														target={target}
														onClick={() => {
															handleCTAClick(
																{
																	event: BUTTON_INTERACTION.CTA_CLICK,
																	link: { type, link, target },
																},
																{ click_text: label, redirect_to: link },
															);
														}}
													>
														<Text variant="paragraph_M_bold">{label}</Text>
													</Link>
												) : (
													<Link
														className="nav-link"
														href={`tel:${phoneNumber}`}
														target={"_self"}
														onClick={handleCallLinkClick}
													>
														<Text variant="paragraph_M_bold">{label}</Text>
													</Link>
												)}
											</li>
										),
									)}
									<li className="nav-item">
										<Button
											modalConfiguration={modalConfiguration}
											className="nav-link"
											darkBg={!isMobile ? lightHeader : false}
											buttonText={"Apply Now"}
											type={"primary"}
											onClick={() => {
												handleCTAClick(
													{
														event: modalConfiguration.length
															? BUTTON_INTERACTION.MODAL_FORM_BUTTON_CLICK
															: BUTTON_INTERACTION.APPLY_BUTTON_CLICK,
														link: {
															type: applyNowLink?.type,
															link: applyNowLink?.link,
														},
													},
													{
														page_section: pageSection,
														click_text: "Apply Now",
														redirect_to: applyNowLink?.link,
													},
												);
											}}
										/>
									</li>
								</ul>
							</nav>
						</StickyHeaderWrapper>
					) : null}
					{topNav?.length ? (
						<TopNav bgColor={topNav?.[0]?.bgColor}>
							{topNav?.[0]?.topNavItems.map((topNavItem, index) => {
								return (
									<TopNavItem key={index} {...topNav?.[0]}>
										<Link href={topNavItem?.link?.link}>
											<Text className="top-nav-link" variant="paragraph_S_bold">
												{topNavItem.text}
											</Text>
										</Link>
									</TopNavItem>
								);
							})}
						</TopNav>
					) : null}
					{!hidePreNav ? (
						<AncillaryNav className="ancillary-nav" lightHeader={lightHeader}>
							<AncillaryNavActions>
								{callUsJSX}
								{!disableLogin ? (
									!userAvatar ? (
										<Link
											darkBg={lightHeader}
											iconConfig={{ iconName: "avatar" }}
											onClick={() => setShowLoginModal(true)}
											className="header-login-link"
											as="button"
											aria-haspopup="dialog"
											aria-expanded={showLoginModal}
											aria-controls="authentication-login-modal"
										>
											<Text variant="paragraph_S_bold" color={textColor}>
												{loginLabel}
											</Text>
										</Link>
									) : (
										userAvatarJSX
									)
								) : null}
							</AncillaryNavActions>
						</AncillaryNav>
					) : null}
					{!disableLogin ? (
						<AuthenticationModal
							className="authentication-modal"
							show={showLoginModal}
							apiBaseUrl={apiBaseUrl}
							globalHostName={globalHostName}
							isStaticBuild={isStaticBuild}
							getXCSRFToken={getXCSRFToken}
							handleModalClose={() => setShowLoginModal(false)}
							loginApiEndPoint={`${apiBaseUrl}/accounts/login/`}
						/>
					) : null}
					<NavBar
						className="navigation-header-main-nav"
						disableHamburger={disableHamburger}
					>
						<NavBrand logoWidth={logo?.width}>
							<Link href="/">
								<img
									src={getLogo() || brandLogoInput || brandLogoDefault}
									alt={brandLogoAlt || "Brand Logo"}
									width={isB2U ? logo?.width || "250px" : "174px"}
									height={isB2U ? logo?.height || "40px" : undefined}
								/>
							</Link>
						</NavBrand>
						{!paidLander && (
							<>
								<NavMenu
									className="navigation-header-desktop-menu"
									lightHeader={lightHeader}
								>
									{menuList.map((menu, index) => {
										if (menu?.type == "LinkMenu") {
											return (
												<Link
													className="nav-additional-link"
													href={menu.cta?.link?.link}
													target={menu.cta?.link?.target}
													key={index}
													onClick={() => {
														handleCTAClick(menu.cta, {
															click_text: menu.text,
															redirect_to: menu.cta.link.link,
															page_section: pageSection,
														});
													}}
												>
													<Text variant="paragraph_S_bold" color={textColor}>
														{menu.text}
													</Text>
												</Link>
											);
										} else {
											let dropdownContent = {};
											let dropdownProps = { isContentMegaMenu: false };
											if (menu?.type == "CourseMenu") {
												dropdownContent = (
													<CourseMenu
														{...menu?.items}
														pageSection={pageSection}
														resetMenu={!isOpen[`dropdown${index + 1}`]}
														context={context}
														onClose={() => {
															closeDropDown(index + 1);
														}}
													></CourseMenu>
												);
											} else if (menu?.type == "megaCourseMenu") {
												dropdownContent = (
													<MegaCourseMenu
														{...menu?.items}
														pageSection={pageSection}
														context={context}
													></MegaCourseMenu>
												);
												dropdownProps = { isContentMegaMenu: true };
											} else {
												let listItems = menu?.items?.map((item) =>
													linkListToLinkJSX(item, index + 1),
												);
												if (menu?.footer) {
													listItems?.push(dropdownFooterJSX(menu?.footer));
												}
												dropdownContent = (
													<VerticalList
														style={{ padding: "40px" }}
														listItems={listItems}
													></VerticalList>
												);
											}

											return (
												<Dropdown
													ctaComponent={
														<DropdownLabel
															label={menu?.label}
															color={textColor}
															open={isOpen[`dropdown${index + 1}`]}
															lightHeader={lightHeader}
															aria-expanded={isOpen[`dropdown${index + 1}`]}
															aria-controls={`nav-header-dropdown-${index + 1}`}
															onClick={() => {
																openDropDown(index + 1);
																handleDropdownClick(menu?.label); // data event call
															}}
														/>
													}
													content={dropdownContent}
													contentId={`nav-header-dropdown-${index + 1}`}
													onClose={() => {
														closeDropDown(index + 1);
													}}
													open={isOpen[`dropdown${index + 1}`]}
													overlayTop={25}
													contentTop={25}
													key={`dropdown${index + 1}`}
													{...dropdownProps}
												/>
											);
										}
									})}
								</NavMenu>
							</>
						)}

						<NavActions className="desktop-nav-action">
							{isStickyheaderAdded
								? buttonJSX?.filter(filterApplyNowButton)
								: buttonJSX}
						</NavActions>
						<NavActions className="mobile-nav-action">
							{userAvatar ? userAvatarJSX : null}
							<Icon
								as="button"
								className="nav-hamburger-icon"
								onClick={handleHamburgerClick}
								iconName={mobileMenuIcon}
								fill={
									!lightHeader
										? "var(--primary-neutral-nightshade-800)"
										: "var(--primary-neutral-white)"
								}
							/>
						</NavActions>
					</NavBar>

					<NavMenuMobile
						className="navigation-header-mobile-menu"
						showMenu={showMobileMenu}
					>
						{!paidLander && showMobileMenu && (
							<>
								<Accordion
									accordionItems={accordionMenu}
									onExpand={handleDropdownClick}
									icon="caret"
								/>
							</>
						)}
						<NavMenuMobileFooter>
							{callUsJSX}
							<div className="footerButtons">
								{buttonJSX}
								{!disableLogin ? (
									<Button
										buttonText="Login"
										type="secondary"
										onClick={() => setShowLoginModal(true)}
										className="header-login-link"
										aria-haspopup="dialog"
										aria-expanded={showLoginModal}
										aria-controls="authentication-login-modal"
									/>
								) : null}
							</div>
						</NavMenuMobileFooter>
						{topNavItemsForMobile}
					</NavMenuMobile>
					{showCTAModal && (
						<ModalForm
							hubspotFormContent={modalContent}
							context={context}
							pageSection={pageSection}
							show={showCTAModal}
							handleClose={() => setShowCTAModal(false)}
							isDarkBg={modalContent?.darkBg}
						/>
					)}
				</NavWrapper>
				<CallUsModal
					showModal={showCallUsModal}
					onClose={() => setShowCallUsModal(false)}
					tel={callUsLabel}
					{...callUsModalDetails}
					imageUrl={callUsModalDetails?.image?.image}
					imageAltText={callUsModalDetails?.image?.altTag}
					pageSection={pageSection}
					id="ancillary-nav-header-call-us-modal"
				/>
			</FocusLock>
		</NavHeaderWrapper>
	);
};

NavigationHeaderGeneric.propTypes = {
	logo: Proptypes.object,
	menuList: Proptypes.arrayOf(Proptypes.object),
	callUsLabel: Proptypes.string,
	callUsModalDetails: Proptypes.object,
	loginLabel: Proptypes.string,
	cta: Proptypes.object,
	paidLander: Proptypes.bool,
	disableLogin: Proptypes.bool,
	hidePreNav: Proptypes.bool,
	isHeaderSticky: Proptypes.bool,
	isHeaderLight: Proptypes.bool,
	pageSection: Proptypes.string,
	topNav: Proptypes.arrayOf(Proptypes.object),
};

NavigationHeaderGeneric.defaultProps = {
	logo: { image: "", altTag: "" },
	menuList: [{}],
	callUsLabel: "",
	callUsModalDetails: {},
	loginLabel: "Login",
	cta: null,
	paidLander: false,
	disableLogin: false,
	hidePreNav: false,
	isHeaderSticky: false,
	isHeaderLight: false,
	pageSection: "",
	topNav: [],
};

export default NavigationHeaderGeneric;
